body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f9; /* Нежный фон */
}

header {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header h1 {
  font-size: 24px;
  margin: 0;
}

.container {
  width: 90%;
  margin: 20px auto;
  max-width: 1200px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

form input,
form button {
  margin: 10px 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form button {
  background-color: #28a745;
  color: #fff;
  border: none;
}

form button:hover {
  background-color: #218838;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f9f9f9;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

ul li button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
}

ul li button.edit {
  background-color: #ffc107;
}

ul li button.delete {
  background-color: #dc3545;
}

ul li button:hover {
  opacity: 0.8;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.modal-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}


.modal-dialog {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

